import request from "@/utils/request";

// 查询可领取优惠券
export function couponQueryList() {
  return request.get("/customer/coupon/query/list");
}

// 查询指定状态优惠券
export function couponReceiveList(status) {
  return request.get(`/customer/coupon/receive/list/${status}`);
}

// 查询待领取优惠券详情
export function couponDetail(id) {
  return request.get(`/customer/coupon/one/${id}`);
}

//查询已领取优惠券详情
export function getCouponReceive(receiveId) {
  return request.get(`/customer/coupon/receive/${receiveId}`);
}

// 领取优惠券
export function couponReceive(couponNumber) {
  return request.post(`/customer/coupon/receive/${couponNumber}`);
}

// 下单查询可用优惠券列表
export function couponListMy(orderAmount) {
  return request.get(`/customer/coupon/list/my/${orderAmount}`);
}