<template>
  <div class="wrapper">
    <!-- 即将放置账号登录表单组件, 此处使用div先占个位 -->
    <div class="form">
      <el-form
        ref="ruleFormRef"
        :model="ruleForm"
        status-icon
        :rules="rules"
        label-width="20px"
        class="demo-ruleForm"
      >
        <div class="fonttop">我们的专业咨询人员将与您保持沟通！</div>
        <div class="switchHeader">
          <div
            :class="activeUI == 1 ? 'styledeep1' : 'phoneRelation'"
            @click="switchCase(1)"
          >
            电话联系
          </div>
          <div
            :class="activeUI == 2 ? 'styledeep2' : 'emaillinkup'"
            @click="switchCase(2)"
          >
            邮件沟通
          </div>
        </div>
        <div v-if="activeUI == 1">
          <div>
            <el-form-item class="inputplus" prop="phone">
              <el-input
                class="from-style1"
                placeholder="请输入手机号"
                v-model.number="ruleForm.phone"
              />
            </el-form-item>
          </div>
          <div>
            <el-row :gutter="28">
              <el-col :span="15">
                <el-form-item class="inputplus" prop="imgCode">
                  <el-input
                    class="from-style1-1"
                    style="height: 48px; font-size: 13px"
                    placeholder="请输入图形验证码"
                    maxlength="4"
                    v-model.number="ruleForm.imgCode"
                    id="autofouce"
                  />
                </el-form-item>
              </el-col>
              <el-col
                :span="8"
                style="position: relative; left: -11px; top: 10px"
              >
                <el-image
                  @click="reCodeImg"
                  :src="codeImg"
                  style="width: 122px; height: 46px"
                ></el-image
              ></el-col>
            </el-row>
          </div>
          <div class="from-style2">
            <div>
              <el-form-item class="inputplus" prop="msgCode">
                <el-input
                  class="from-style1"
                  placeholder="请输入短信验证码"
                  v-model.number="ruleForm.msgCode"
                  autocomplete="off"
                  maxlength="6"
                />
              </el-form-item>
              <el-button
                v-if="isCheck"
                class="code"
                :class="{ disabled: isActive }"
                :disabled="isActive"
                @click="getCode"
              >
                {{ isActive ? `剩余${count}秒` : "发送验证码" }}
              </el-button>
              <el-button v-else @click="isrefsend" class="code"
                >重新发送</el-button
              >
            </div>
          </div>
          <div>
            <el-form-item class="inputplus">
              <el-input
                class="from-style1-2"
                style="width: 95%; font-size: 13px"
                placeholder="选填项，您可以输入您的企业名称或者联系人名称，以方便我们更好的认识您。也可以为我们提供一些您生产的需求信息，方便我们更好的理解与沟通您的需求。"
                v-model="ruleForm.content"
                type="textarea"
                maxlength="300"
                show-word-limit
                resize="none"
                :rows="10"
              />
            </el-form-item>
          </div>
        </div>
        <div v-if="activeUI == 2">
          <div>
            <el-form-item class="inputplus" prop="email">
              <el-input
                class="from-style1"
                placeholder="请输入邮箱地址"
                v-model="ruleForm.email"
              />
            </el-form-item>
          </div>
          <div>
            <el-row :gutter="28">
              <el-col :span="15">
                <el-form-item class="inputplus" prop="imgCode">
                  <el-input
                    class="from-style1-1"
                    style="height: 48px; font-size: 13px"
                    placeholder="请输入图形验证码"
                    maxlength="4"
                    v-model.number="ruleForm.imgCode"
                    id="autofouce"
                  />
                </el-form-item>
              </el-col>
              <el-col
                :span="8"
                style="position: relative; left: -11px; top: 10px"
              >
                <el-image
                  @click="reCodeImg"
                  :src="codeImg"
                  style="width: 122px; height: 46px"
                ></el-image
              ></el-col>
            </el-row>
          </div>
          <div>
            <el-form-item class="inputplus">
              <el-input
                class="from-style1-2"
                style="width: 380px; font-size: 13px"
                placeholder="选填项，您可以输入您的企业名称或者联系人名称，以方便我们更好的认识您。也可以为我们提供一些您生产的需求信息，方便我们更好的理解与沟通您的需求。"
                v-model="ruleForm.content"
                type="textarea"
                maxlength="300"
                show-word-limit
                resize="none"
                :rows="10"
              />
            </el-form-item>
          </div>
        </div>
        <el-button class="btn" type="submit" @click="submitForm(ruleFormRef)"
          >提交需求</el-button
        >
      </el-form>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUpdated, reactive } from "vue";
import { FormInstance, FormRules } from "element-plus";
import { useRouter } from "vue-router";
import { getImageCode, getSendCode } from "@/api/login/index";
import { workOrderCommit } from "@/api/work/index";
import { ElMessage } from "element-plus";

const ruleFormRef = ref();
const isCheck = ref(true);
const showtime = ref(false);
const validataOk = ref(false);
const validataImgOk = ref(false);
const isActive = ref(false);
const uuid = ref();
const codeImg = ref();
const address = ref("data:image/gif;base64,");
import emitter from "@/utils/eventBus";
const activeUI = ref(1);
const switchCase = (id) => {
  if (id == 1) {
    activeUI.value = 1;
  } else {
    activeUI.value = 2;
  }
};
const ruleForm = reactive({
  phone: "",
  imgCode: "",
  msgCode: "",
  content: "",
  email: "",
});

onMounted(() => {
  Code();
});
onUpdated(() => {
  clearData();
});
const router = useRouter();

const reCodeImg = () => {
  Code();
};
const clearData = (formEl) => {
  ruleFormRef.value?.resetFields();
};
const Code = () => {
  getImageCode().then((res) => {
    codeImg.value = address.value + res.data.img;
    uuid.value = res.data.uuid;
  });
};
const phone = (rule, value, callback) => {
  if (!value) {
    return callback(new Error("请输入手机号!"));
  }
  setTimeout(() => {
    if (!Number.isInteger(value)) {
      callback(new Error("请输入数字!"));
    } else {
      if (value.toString().length < 11) {
        callback(new Error("请输入11位数手机号!"));
      } else {
        validataOk.value = true;
        callback();
      }
    }
  }, 200);
};
const msgCode = (rule, value, callback) => {
  if (value === "") {
    callback(new Error("请输入验证码!"));
  } else {
    if (value.toString().length < 6) {
      callback(new Error("请输入6位数验证码!"));
    } else {
      callback();
    }
  }
};
const imgCode = (rule, value, callback) => {
  if (value === "") {
    callback(new Error("请输入图形验证码!"));
  } else {
    if (value.toString().length < 4) {
      callback(new Error("请输入4位数图形验证码!"));
    } else {
      validataImgOk.value = true;
      callback();
    }
  }
};
const rules = reactive({
  phone: [
    { validator: phone, trigger: "blur" },
    {
      pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
      message: "请输入正确的手机号码",
      trigger: "blur",
    },
  ],
  imgCode: [{ validator: imgCode, trigger: "blur" }],
  msgCode: [{ validator: msgCode, trigger: "blur" }],
  email: [
    { required: true, message: "请输入邮箱地址", trigger: "blur" },
    {
      type: "email",
      message: "请输入正确的邮箱地址",
      trigger: ["blur", "change"],
    },
  ],
});
const emit = defineEmits(["phoneData"]);
const submitForm = (formEl) => {
  if (!formEl) return;
  formEl.validate((valid) => {
    if (valid) {
      showtime.value = false;
      let data;
      if (activeUI.value == 1) {
        data = {
          phone: ruleForm.phone,
          uuid: uuid.value,
          msgCode: ruleForm.msgCode,
          content: ruleForm.content,
        };
      } else {
        data = {
          email: ruleForm.email,
          uuid: uuid.value,
          content: ruleForm.content,
          code: ruleForm.imgCode,
        };
      }
      workOrderCommit(data)
        .then((res) => {
          if (res.code == 200) {
            ElMessage({
              message: "提交成功",
              type: "success",
            });
            emit("closeOpen");
            emitter.emit("openClose");
          } else {
            ElMessage({
              message: res.message,
              type: "error",
            });
          }
        })
        .catch((error) => {
          if (error.err.response.data.code == 400) {
            ElMessage({
              message: error.err.response.data.message,
              type: "error",
            });
          }
        });
    } else {
      return false;
    }
  });
};
const isrefsend = () => {
  getCode();
};
const SendPhoneFn = () => {
  let data = {
    phoneNumber: ruleForm.phone,
    code: ruleForm.imgCode,
    uuid: uuid.value,
  };
  ifpassEnter.value = 2;
  getSendCode(data).then((res) => {
    if (res.code == 200) {
      isCheck.value = true;
      ifpassEnter.value = 1;
      isActive.value = true;
      var timer = setInterval(function () {
        count.value--;
        if (count.value == 0) {
          clearInterval(timer);
          isCheck.value = false;
          count.value = 60;
        }
      }, 1000);
    } else {
      ElMessage({
        message: res.message,
        type: "error",
      });
      document.getElementById("autofouce").focus();
      ifpassEnter.value = 1;
    }
  });
};
const count = ref(60);
const ifpassEnter = ref(1);
const getCode = () => {
  if (validataOk.value && validataImgOk.value) {
    var telreg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
    if (
      !telreg.test(ruleForm.phone) ||
      ruleForm.phone.toString().length != 11
    ) {
      return { data: false, msg: "手机号码格式不正确" };
    } else {
      if (ifpassEnter.value == 1) {
        SendPhoneFn();
      }
    }
  } else {
    ElMessage.error("请输入正确的手机号或图形验证码");
  }
};
</script>

<style scoped lang="less">
.fonttop {
  width: 320px;
  height: 18px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #111111;
  margin: 10px 0 21px 59px;
}
.wrapper {
  width: 460px;
  background: #fff;
  min-height: 400px;
  transform: translate3d(100px, 0, 0);
}
.switchHeader {
  display: flex;
  margin-left: 30px;
  .phoneRelation {
    cursor: pointer;
    width: 68px;
    height: 15px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin: 0 28px 0 95px;
  }
  .emaillinkup {
    cursor: pointer;
    width: 68px;
    height: 15px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
  }
  .styledeep1 {
    cursor: pointer;
    width: 68px;
    height: 27px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ce1200;
    margin: 0 28px 0 95px;
    border-bottom: 2px solid #ce1200;
  }
  .styledeep2 {
    cursor: pointer;
    width: 68px;
    height: 27px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ce1200;
    border-bottom: 2px solid #ce1200;
  }
}
.form {
  padding: 0 20px 0 20px;
  .topfont {
    width: 391px;
    height: 18px;
    font-size: 17px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
  }
  .form-item :first-child {
    margin-top: 20px;
  }

  &-item {
    margin-bottom: 18px;
    .input {
      position: relative;
      width: 476px;
      height: 44px;
      border-radius: 5px;
      top: -40px;
      left: -80px;
      > i {
        width: 34px;
        height: 34px;
        background: #cfcdcd;
        color: #fff;
        position: absolute;
        left: 1px;
        top: 1px;
        text-align: center;
        line-height: 34px;
        font-size: 18px;
      }
      input {
        padding-left: 16px;
        border: 1px solid #cfcdcd;
        height: 36px;
        border-radius: 5px;
        line-height: 36px;
        width: 100%;
        &.error {
          border-color: @priceColor;
        }
        &.active,
        &:focus {
          border-color: @xtxColor;
        }
      }

      .code.disabled {
        cursor: wait;
      }
    }

    > .error {
      position: absolute;
      font-size: 12px;
      line-height: 28px;
      color: @priceColor;
      i {
        font-size: 14px;
        margin-right: 2px;
      }
    }
  }
}
.from-style1 {
  width: 380px;
  height: 48px;
  margin-top: 6px;
  border-radius: 5px;
  font-size: 13px;
  ::v-deep .el-input__inner {
    padding-left: 10px !important;
  }
}
.from-style1-1 {
  ::v-deep .el-input__inner {
    padding-left: 10px !important;
  }
}
.from-style1-2 {
  ::v-deep .el-textarea__inner {
    position: relative;
    display: block;
    resize: vertical;
    padding: 5px 11px;
    line-height: 1.5;
    box-sizing: border-box;
    width: 100%;
    font-size: inherit;
    font-family: inherit;
    color: var(--el-input-text-color, var(--el-text-color-regular));
    background-color: var(--el-input-bg-color, var(--el-fill-color-blank));
    background-image: none;
    -webkit-appearance: none;
    box-shadow: 0 0 0 1px var(--el-input-border-color, var(--el-border-color))
      inset;
    border-radius: var(--el-input-border-radius, var(--el-border-radius-base));
    transition: var(--el-transition-box-shadow);
    border: none;
    padding-left: 16px;
  }
}
.inputplus {
  position: relative;
  left: -6px;
  top: 10px;
}
.code {
  position: absolute;
  right: 52px;
  top: 237px;
  text-align: center;
  line-height: 34px;
  font-size: 14px;
  color: #ce1200;
  width: 90px;
  height: 34px;
  cursor: pointer;
  background-color: #fff;
  border: none;
}
.code.disabled {
  color: grey;
}
.btn {
  font-size: 16px;
  border: none;
  background-color: #ce1200;
  height: 48px;
  width: 380px;
  border-radius: 5px;
  position: absolute;
  bottom: 80px;
  left: 33px;
  bottom: -62px;
  color: #fff;
  .el-button:focus,
  .el-button:hover {
    color: #ffffff;
    border-color: #ce1200;
    background-color: #ce1200;
    outline: 0;
  }
}

.input1 {
  border: 1px solid #cfcdcd;
  height: 38px;
  line-height: 38px;
  width: 68%;
  border-radius: 5px;
  position: relative;
  .input2 {
    position: absolute;
    border: 1px solid #cfcdcd;
    height: 36px;
    line-height: 36px;
    width: 90px;
    left: 228px;
    top: -0.0625rem;
    border-radius: 5px;
  }
}
</style>
