<template>
  <!-- <div class="container-feel"> -->
  <div class="container-feel-ui">
    <div
      v-for="(item, index) in experienceList"
      :key="item.id + index"
      class="container-feel-ui-li"
    >
      <div class="feel-content">
        <div class="feel-content-img">
          <div class="feel-content-img-img">
            <img :src="item.userPic" alt="" />
          </div>
          <div class="fell-content-title">
            <div class="feel-puls">
              <span class="fell-content-title-plus">{{ item.masterId }}</span>
              <span class="fell-content-title-small">{{
                item.masterName
              }}</span>
            </div>
            <p class="fell-content-title-game">{{ item.userName }}</p>
            <p class="fell-content-title-text">
              产品使用时间第
              <span class="fell-content-title-day">{{ item.days }}</span>
              天
            </p>
          </div>
        </div>
        <div class="feel-btn-text">
          <p>{{ item.experience }}</p>
        </div>
      </div>
    </div>
  </div>
  <!-- </div> -->
</template>

<script setup>
import { ref, onMounted, defineEmits } from "vue";
import { experience } from "@/api/details";
import { useRoute } from "vue-router";

const emits = defineEmits();
const route = useRoute();
const experienceList = ref([]);
const getExperience = async (productId = route.query.id) => {
  try {
    const res = await experience(productId);
    experienceList.value = res.data;
    emits("changeClick", experienceList.value[0]);
  } catch (error) {
    console.log(error);
  }
};

onMounted(() => {
  getExperience();
});
</script>

<style lang="less" scoped>
.container-feel-ui {
  column-count: 3;
  column-gap: 30px;
  padding-top: 30px;
  .container-feel-ui-li {
    break-inside: avoid;
    margin-bottom: 30px;
  }
  .feel-content {
    background: #f2f2f2;
    border-radius: 15px;
    .feel-content-img {
      display: flex;
      .feel-content-img-img {
        img {
          width: 90px;
          height: 90px;
          margin: 48px 14px 25px 47px;
          border-radius: 10px;
        }
      }
      .fell-content-title {
        margin-top: 58px;
        .feel-puls {
          .fell-content-title-plus {
            font-size: 16px;
            font-weight: bold;
            color: #333333;
          }
          .fell-content-title-small {
            font-size: 12px;
            font-weight: 400;
            color: #999999;
            margin-left: 11px;
          }
        }
        .fell-content-title-game {
          margin-top: 14px;
          font-size: 14px;
          font-weight: 400;
          color: #666666;
        }
        .fell-content-title-text {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          .fell-content-title-day {
            font-size: 16px;
            color: #ce1200ff;
          }
        }
      }
    }
    .feel-btn-text {
      padding: 0 47px 44px 47px;
      text-indent: 2em;
    }
  }
}
</style>
