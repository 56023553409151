<template>
  <div class="shop-container">
    <div class="video-s">
      <video
        @click="getBayId"
        id="bottomMedio"
        v-if="applicationVideo"
        :src="applicationVideo"
        height="670"
        width="1200"
        :fill="true"
        :controls="true"
      ></video>
      <p v-else></p>
    </div>
    <ui v-for="item in application" :key="item.index"
      ><li><img :src="item.img" alt="" /></li
    ></ui>
  </div>
</template>

<script setup>
import { defineProps, reactive } from "vue";
import { videoPlay } from "vue3-video-play"; // 引入视频播放的组件
import "vue3-video-play/dist/style.css";
// import useScrollTop from "@/hooks/useScrollTop";
// const scrollTop = useScrollTop();
defineProps({
  application: {
    type: Array,
  },
  applicationVideo: {
    type: String,
  },
  applicationImg: {
    type: String,
  },
});
const getBayId = () => {
  document.getElementById("bottomMedio").onfullscreenchange = function () {
    if (!document.fullscreenElement) {
      document.getElementById("bottomMedio").scrollIntoView();
    }
  };
};

const playerOptions = reactive({
  width: "1200px", //播放器高度
  height: "670px", //播放器高度
  color: "#409eff", //主题色
  title: "", //视频名称
  webFullScreen: false, //网页全屏
  speed: true, //是否支持快进快退
  currentTime: 0, //跳转到固定播放时间(s)
  muted: false, //静音
  preload: "auto",
  fluid: true,
  autoPlay: false, //自动播放
  loop: false, //循环播放
  mirror: false, //镜像画面
  control: true, //是否显示控制器
  ligthOff: false, //关灯模式
  volume: 0.3, //默认音量0-1
  language: "zh-CN",
  // src: src1, //视频源
  // poster: poster, //封面
  speedRate: [1.0, 1.25, 1.5, 2.0], // 可选的播放速度
  controlBtns: [
    "audioTrack", //音轨切换按钮
    "quality", //视频质量切换按钮
    // "speedRate", //速率切换按钮
    "volume", //音量
    // "setting",//设置
    // "pip", //画中画按钮
    // "pageFullScreen", //网页全屏按钮
    "fullScreen", //全屏按钮
  ],
});
</script>

<style lang="less" scoped>
.shop-container {
  width: 1200px;
  background-color: #fff;
  margin: 20px 0 0 15px;
  img {
    width: 100%;
    // border-radius: 10px;
  }
  .video-s {
    margin: 0 auto;
    margin-bottom: -5px;
    // .img-i {
    //   width: 1200px;
    //   height: 670px;
    //   margin: 0 auto;
    // }
  }
}
</style>
